@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

[x-cloak] {
    display: none;
}

html,
div {
    scroll-behavior: smooth;
}

a.filter-card:hover {
    border-color: #064386;
}

a.filter-card:hover > p {
    background-color: #064386;
    color: #ffffff;
}

.prose ol > li::before {
    color: #313131 !important;
}

/*
 * chrome black outline
 */
*,
*:focus,
*:hover {
    outline: none;
}

input:checked ~ .toggle__dot {
    transform: translateX(100%);
    background-color: #064386;
}

.toggle__dot {
    top: -0.25rem;
    left: -0.25rem;
    transition: all 0.3s ease-in-out;
}
